/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/assets/css/tailwind.css';
import { configure } from 'axios-hooks';
import Axios from 'axios';

const axios = Axios.create({
  baseURL: process.env.GATSBY_API_V1_BASEURL,
});

configure({ axios });
